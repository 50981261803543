import context, { getDefaultState } from 'chimera/all/store/context'
import deepmerge from 'deepmerge'

export const state = () => {
  return deepmerge(getDefaultState(), {
    country: 'fr',
    language: 'fr',
  })
}

export default {
  ...context,
  state,
}
